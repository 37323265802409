import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';

import { CsvParserService, ResetPasswordsService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';
import DisplayService from '../../util/DisplayService';
import useAuthentication from '../../store/useAuthentication';

function ResetUserPasswords() {
  const authenticationState = useAuthentication()[0];
  const authenticatedUser = authenticationState.authentication.user;
  const [updateCount, setUpdateCount] = useState(0);
  const [file, setFile] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(null);
  const fileInput = React.useRef<HTMLInputElement>();
  const { classes } = PageStyles();

  const isSecurityAdmin = authenticatedUser.roles.filter(role => role.code === 'SECURITY_ADMIN').length > 0;

  const openConfirm = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const processFile = async () => {
    if (fileInput.current.files[0]) {
      setUploadSuccessful(null);
      setFile(fileInput.current.files[0]);
      const reader = new window.FileReader();

      reader.onload = e => {
        const data = CsvParserService.parse(e.target.result.toString(), ',', false);

        setUpdateCount(data.length);
      };

      reader.readAsText(fileInput.current.files[0]);
    } else {
      toast.error('Uploaded file does not exist');
    }
  };

  const resetAfterUpload = () => {
    setFile(null);
    setUpdateCount(0);
    setUploadSuccessful(true);
  };

  const showError = useCallback(error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error resetting passwords.',
      false,
    );

    displayedError.autoDismiss = false;
    toast.error(displayedError.message);
    setUploadSuccessful(false);
  }, []);

  const doResetPasswords = async () => {
    if (file) {
      const formData = new FormData();

      formData.append('file', file);
      setShowConfirm(false);
      setUploading(true);

      await ResetPasswordsService.resetPasswords(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(resetAfterUpload)
        .catch(showError)
        .finally(() => {
          setUploading(false);
        });
    }
  };

  return (
    <Paper className={classes.paper}>
      <Dialog open={showConfirm} onClose={closeConfirm}>
        <DialogTitle>Reset passwords now?</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to reset {updateCount} {updateCount === 1 ? 'password' : 'passswords'}?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirm} color='secondary'>
            Cancel
          </Button>
          <Button
            onClick={doResetPasswords}
            disabled={uploading}
            color='primary'
            autoFocus
          >
            Reset Passwords
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant='h6' gutterBottom>
        Reset User Passwords
      </Typography>
      <p>
        Upload a .csv file of user IDs (one per line) to <b>reset</b> passwords and notify users via email.
      </p>
      {isSecurityAdmin ?
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <input
              id='csv-file-reset-user-passwords'
              type='file'
              ref={fileInput}
              onChange={processFile}
            />
          </Grid>
          <Grid item lg={4}>
            {updateCount > 0 && (
              <Box>
                Reset {updateCount} {updateCount === 1 ? 'password' : 'passwords'}
              </Box>
            )}
          </Grid>
          <Grid item lg={4}>
            {updateCount > 0 && (
              <Button
                color='primary'
                disabled={uploading}
                onClick={openConfirm}
                variant='contained'
              >
                {uploading ? 'Uploading...' : 'Upload'}
              </Button>
            )}
            {uploadSuccessful === true && (
              <div style={{ color: 'green' }}>
                <FontAwesomeIcon
                  style={{
                    fontSize: 16,
                    marginRight: '0.75rem',
                  }}
                  icon={faCheckCircle}
                />
                <span>Upload successful!</span>
              </div>
            )}
            {uploadSuccessful === false && (
              <div style={{ color: 'red' }}>
                <FontAwesomeIcon
                  style={{
                    fontSize: 16,
                    marginRight: '0.75rem',
                  }}
                  icon={faTimesCircle}
                />
                <span>Error occurred</span>
              </div>
            )}
          </Grid>
        </Grid>
        :
        <Typography variant='h6' gutterBottom>
          You do not have permission to use this tool.
        </Typography>
      }
    </Paper>
  );
}

export default withRouter(ResetUserPasswords);
