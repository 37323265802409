import React, { useEffect, useState } from 'react';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Paper } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import {
  AdminFusionAuthUserDataService,
  ParentService,
  Profile,
  SitterService,
  User,
  UserPIIRemovalService,
  UserService,
} from 'us-web-services';
import ProfileEditForm from './ProfileEditForm';
import UserEditForm from './UserEditForm';
import PageStyles from '../../styles/PageStyles';
import useAuthentication from '../../store/useAuthentication';
import useUser from '../../store/useUser';
import ValidationService from '../../util/ValidationService';
import DisplayService from '../../util/DisplayService';
import SitterEditForm from './SitterEditForm';
import ConfirmDialog from '../common/ConfirmDialog';

function UserEdit(props: RouteComponentProps<{ id: string }>) {
  const authenticationState = useAuthentication()[0];
  const userActions = useUser()[1];
  const { classes } = PageStyles();
  const adminUser = authenticationState.authentication.user;
  const { match, history } = props;
  const [user, setUser] = useState<User>({});
  const [profile, setProfile] = useState<Profile>({});
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [removingPii, setRemovingPii] = useState(false);

  useEffect(() => {
    if (!user.id || Number.parseInt(match.params.id, 10) !== user.id) {
      const getUser = async () => {
        const id = +match.params.id;

        const userConfig = {
          params: {
            fields: 'superUser',
          },
        };

        const userResponse = await UserService.get(id, userConfig);
        const userData = userResponse.data.data;

        setUser(userData);
        if (userData.parent) {
          const parentResponse = await ParentService.get(userData.id);

          setProfile(parentResponse.data.data);
        } else if (userData.sitter) {
          const sitterConfig = {
            params: {
              userId: adminUser.id,
            },
          };
          const sitterResponse = await SitterService.get(userData.id, sitterConfig);

          setProfile(sitterResponse.data.data);
        }
      };

      getUser();
    }
  }, [user.id, adminUser.id, match.params.id]);

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error updating this user.',
    );

    toast.error(displayedError.message);
  };

  const saveUser = async () => {
    if (!ValidationService.isEmail(user.email)) {
      toast.error('Email is required and must be valid.');

      return;
    }

    const userData: User = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      active: user.active,
      roles: user.roles,
      policyAccepted: user.policyAccepted,
    };

    if (user.removePhoto) {
      userData.pictureUrl = '';
    }

    if (user.password) {
      if (user.password === user.password2) {
        userData.password = user.password;
      } else {
        toast.error('Passwords must match');

        return;
      }
    }

    try {
      const userResponse = await UserService.patch(user.id, userData);
      const { data } = userResponse.data;

      data.sitter = user.sitter;
      data.parent = user.parent;
      data.superUser = user.superUser;

      userActions.setUser(data);
    } catch (error) {
      showError(error);

      return;
    }

    const profileData: Profile = {
      id: user.id,
      address: profile.address,
      phone: profile.phone,
    };

    if (user.parent) {
      try {
        const parentResponse = await ParentService.patch(user.id, profileData);

        userActions.setProfile(parentResponse.data.data);
      } catch (error) {
        showError(error);

        return;
      }
    } else if (user.sitter) {
      profileData.dob = profile.dob;
      try {
        const sitterResponse = await SitterService.patch(user.id, profileData);

        userActions.setProfile(sitterResponse.data.data);
      } catch (error) {
        showError(error);

        return;
      }
    }

    const userRefreshData = {
      userId: user.id,
    };

    await AdminFusionAuthUserDataService.refresh(userRefreshData);

    toast.success('User updated successfully');
    history.push(`/users/lookup?id=${user.id}`);
  };

  const cancelEdit = async () => {
    history.push(`/users/lookup?id=${user.id}`);
  };

  const confirmDeletePii = () => {
    setShowConfirmDialog(true);
  };

  const deletePii = async () => {
    setShowConfirmDialog(false);
    setRemovingPii(true);
    UserPIIRemovalService.delete(user.id)
      .then(() => {
        toast.success('User PII removed successfully');
      })
      .catch(error => {
        showError(error);
      })
      .finally(() => {
        setRemovingPii(false);
      });
  };

  return (
    <Paper className={classes.paper}>
      <>
        <ConfirmDialog
          confirmAction={deletePii}
          open={showConfirmDialog}
          setOpen={setShowConfirmDialog}
          title='Confirm PII Removal'
          text="Are you sure you want to remove this user's PII?"
        />
        {user.id ? (
          <UserEditForm user={user} setUser={setUser} />
        ) : (
          'User not found'
        )}{' '}
        <br />
        {user.sitter && profile.id ? (
          <SitterEditForm profile={profile} setProfile={setProfile} />
        ) : (
          ' '
        )}
        {profile.id && (
          <ProfileEditForm profile={profile} setProfile={setProfile} />
        )}
        <div className={classes.buttons}>
          <Button
            disabled={removingPii}
            onClick={confirmDeletePii}
            className={classes.button}
            style={{
              backgroundColor: 'red',
              color: 'white',
            }}
          >
            <FontAwesomeIcon
              style={{
                fontSize: 16,
                marginRight: '0.75rem',
              }}
              icon={faTrash}
            />
            Delete PII
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={cancelEdit}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={saveUser}
            className={classes.button}
          >
            Save
          </Button>
        </div>
      </>
    </Paper>
  );
}

export default withRouter(UserEdit);
