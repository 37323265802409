import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import CssBaseline from '@mui/material/CssBaseline';
import Hidden from '@mui/material/Hidden';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Grid from '@mui/material/Grid';

import ConnectedLogin from './components/Login';
import Navigator from './navigation/Navigator';
import Routes from './Routes';
import useAuthentication from './store/useAuthentication';

const drawerWidth = 275;

const AppStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: '#eaeff1',
  },
  mainContent: {
    flex: 1,
    padding: '10px 10px 0',
    marginLeft: 20,
    marginTop: 10,
  },
}));

const initialState = {
  mobileOpen: false,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'MOBILE_OPEN':
      return {
        ...state,
        mobileOpen: action.payload.mobileOpen,
      };
    default:
      return state;
  }
};

const routeNames = {
  '/': 'User Lookup',
  '/ab-test-assignment': 'AB Test Assignment',
  '/affiliations': 'Affiliation Lookup',
  '/affiliations/:id': 'Affiliation Edit',
  '/affiliations-merge': 'Affiliation Merge',
  '/affiliation-create': 'Affiliation Create',
  '/api-profiler': 'API Profiler',
  '/batch-mailchimp': 'Batch Mailchimp',
  '/bookings/lookup': 'Booking Lookup',
  '/bookings/edit/:id': 'Booking Edit',
  '/booking-groups/edit/:id': 'Booking Group Edit',
  '/colleges': 'College Lookup',
  '/colleges/:id': 'College Edit',
  '/college-create': 'College Create',
  '/companies': 'Company Lookup',
  '/companies/:id': 'Company Edit',
  '/company-benefits/:id': 'Company Benefit Edit',
  '/company-benefit-create/:id': 'Company Benefit Create',
  '/company-create': 'Company Create',
  '/company-employees': 'Employee Lookup',
  '/company-employees/add': 'Employee Add',
  '/duplicate-sitters': 'Duplicate Sitter Lookup',
  '/job-moderation': 'Job Moderation',
  '/local-groups': 'Local Group Lookup',
  '/local-groups/:id': 'Local Group Edit',
  '/local-groups-merge': 'Local Group Merge',
  '/local-group-create': 'Local Group Create',
  '/master-service-areas': 'MSA Lookup',
  '/master-service-areas/:id': 'MSA Edit',
  '/master-service-areas/:subid/prices/:id': 'MSA Price Edit',
  '/master-service-areas/:subid/price-create': 'MSA Price Create',
  '/master-service-area-create': 'MSA Create',
  '/master-service-area-tiers': 'MSA Tier Lookup',
  '/master-service-area-tiers/:id': 'MSA Tier Edit',
  '/master-service-area-tier-create': 'MSA Tier Create',
  '/membership-renewal': 'Membership Renewal',
  '/orders/lookup': 'Order Lookup',
  '/orders/payout': 'Send Order Payout',
  '/partners': 'Partner Lookup',
  '/partners/:id': 'Partner Edit',
  '/partner-create': 'Partner Create',
  '/press': 'Press Lookup',
  '/press/:id': 'Press Edit',
  '/press-create': 'Press Create',
  '/reset-user-passwords': 'Reset User Passwords',
  '/schools': 'School Lookup',
  '/schools/:id': 'School Edit',
  '/schools-merge': 'School Merge',
  '/school-create': 'School Create',
  '/seo/cities': 'City SEO Lookup',
  '/seo/cities/:id': 'City SEO Edit',
  '/seo/states': 'State SEO Lookup',
  '/seo/states/:state': 'State SEO Edit',
  '/sso-test': 'SSO Test',
  '/tags': 'Tag Lookup',
  '/tags/:id': 'Tag Edit',
  '/tag-create': 'Tag Create',
  '/users/batch': 'Batch User Actions',
  '/users/edit/:id': 'User Edit',
  '/users/lookup': 'User Lookup',
  '/users/plans': 'User Plans',
  '/users/reviews': 'User Reviews',
  '/users/transactions': 'User Transactions',
  '/video-moderation': 'Video Moderation',
  '/vouchers': 'Voucher Lookup',
  '/vouchers/:id': 'Voucher Edit',
  '/voucher-create': 'Voucher Create',
  '/voucher-not-found': 'Voucher Not Found',
  '/winback-codes': 'Winback Code Lookup',
  '/winback-codes/:id': 'Winback Code Edit',
  '/winback-code-create': 'Winback Code Create',
  '/zip-codes': 'Zip Code Lookup',
  '/zip-codes/:id': 'Zip Code Edit',
  '/zip-code-create': 'Zip Code Create',
};

const AppContainer = (props: RouteComponentProps) => {
  const { history } = props;

  const updatePageTitle = (route) => {
    const routeKey = route
      .replace(/\/[0-9]+$/, '/:id')
      .replace(/\/[0-9]+\//, '/:subid/')
      .replace(/\/[A-Z]{2}$/, '/:state');

    let adminTitle = '';

    if (routeNames[routeKey]) {
      adminTitle += `${routeNames[routeKey]} - `;
    }

    adminTitle += 'UrbanSitter Admin';

    window.document.title = adminTitle;
  };

  useEffect(() => {
    updatePageTitle(window.location.pathname);

    history.listen(() => {
      updatePageTitle(history.location.pathname);
      window.scrollTo(0, 0);
    });
  }, []);

  const [authenticationState] = useAuthentication();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const handleDrawerToggle = () => {
    dispatch({
      type: 'MOBILE_OPEN',
      payload: {
        mobileOpen: !state.mobileOpen,
      },
    });
  };

  const { classes } = AppStyles();
  const { mobileOpen } = state;

  return (
    authenticationState.authentication.isAuthenticated === true ? (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation='js'>
              <Navigator
                paperProps={{ style: { width: drawerWidth } }}
                variant='temporary'
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden smDown implementation='css'>
              <Navigator
                paperProps={{ style: { width: drawerWidth } }}
                variant='permanent'
              />
            </Hidden>
          </nav>
          <div className={classes.appContent}>
            <main className={classes.mainContent}>
              <Routes />
            </main>
          </div>
        </div>
      </LocalizationProvider>
    ) : (
      <Grid
        container
        alignItems='center'
        direction='column'
        justifyContent='center'
        style={{ minHeight: '50vh' }}
      >
        <Grid item xs={5}>
          <ConnectedLogin />
        </Grid>
      </Grid>
    )
  );
};

export default withRouter(AppContainer);
