import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Paper, Button } from '@mui/material';
import {
  Reimbursement,
  ReimbursementOrderService,
  UsOon,
  UsOonService,
} from 'us-web-services';
import { Table, TableInstance } from 'src/components/common/Table';
import { DisplayService } from 'src/util';
import useUser from '../../../store/useUser';
import DateUtil from '../../../util/DateUtil';
import ReimbursementEdit from './ReimbursementEdit';

function ReimbursementLookup() {
  const userCache = useUser()[0];
  const tableRef = React.useRef<TableInstance>();
  const [reimbursement, setReimbursement] = useState<Reimbursement>({});
  const [openEditReimbursement, setOpenEditReimbursement] = useState(false);

  const refreshReimbursements = () => tableRef.current?.fetchData();

  const postUpdate = () => {
    refreshReimbursements();
    setOpenEditReimbursement(false);
    toast.success('Plan updated');
  };

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error updating the plan.',
    );

    toast.error(displayedError.message);
  };

  const updateReimbursement = async (usOonId: number, data: UsOon) => {
    UsOonService.patch(usOonId, data).then(postUpdate).catch(showError);
  };

  const COLUMNS_ORDERS = useMemo(
    () => [
      {
        id: 'reimbursement',
        header: 'Reimbursement',
        columns: [
          {
            header: 'ID',
            accessorKey: 'id',
            enableEditing: false,
          },
          {
            header: 'Order Id',
            accessorKey: 'orderId',
            enableEditing: false,
          },
          {
            header: 'Voucher Id',
            accessorKey: 'voucherId',
            enableEditing: false,
          },
          {
            header: 'Voucher Code',
            accessorKey: 'voucherCode',
            enableEditing: false,
          },
          {
            header: 'Amount',
            accessorKey: 'amountToDeduct',
            enableEditing: false,
          },
          {
            header: 'Booking Id',
            accessorKey: 'bookingId',
            enableEditing: false,
          },
          {
            header: 'Care type Category',
            accessorKey: 'careTypeCategory',
            enableEditing: false,
          },
          {
            header: 'Qualified Dependent',
            accessorKey: 'qualifiedDependent',
            Cell: ({ cell }) => cell.getValue() ? 'Yes' : 'No',
          },
          {
            header: 'Start Date',
            accessorFn: rowData =>
              DateUtil.getReadableDateTime(rowData.startDate),
            enableEditing: false,
          },
          {
            header: 'End Date',
            accessorFn: rowData =>
              DateUtil.getReadableDateTime(rowData?.endDate),
            enableEditing: false,
          },
          {
            header: 'Actions',
            Cell: ({ cell }) => (
              <Button
                size='small'
                variant='outlined'
                color='secondary'
                onClick={() => {
                  setReimbursement(cell?.row?.original);
                  setOpenEditReimbursement(true);
                }}
                style={{ margin: 5 }}
              >
                Edit
              </Button>
            ),
          },
        ],
      },
    ],
    [],
  );

  const paramsOrders = () => [
    {
      key: 'userId',
      value: userCache?.user?.id?.toString(),
    },
  ];

  return (
    <Paper>
      <>
        <ReimbursementEdit
          reimbursement={reimbursement}
          updateReimbursement={updateReimbursement}
          open={openEditReimbursement}
          setOpen={setOpenEditReimbursement}
        />
        <Table
          columns={COLUMNS_ORDERS}
          ref={tableRef}
          retrieveData={ReimbursementOrderService.findAllByUserId}
          params={paramsOrders}
          alwaysApplyParams
        />
      </>
    </Paper>
  );
}

export default ReimbursementLookup;
