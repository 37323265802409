export default {
  PARENT: {
    id: 5,
  },
  PAYMENT_ADMIN: {
    id: 30,
    code: 'PAYMENT_ADMIN',
  },
  SECURITY_ADMIN: {
    id: 31,
  },
  SITTER: {
    id: 4,
  },
  AGENCY: {
    id: 9,
  },
  SUPER_USER: {
    id: 11,
  },
  MANAGER_ADMIN: {
    id: 32,
  },
  PRODUCT_ADMIN: {
    id: 33,
  },
};
