import React, { useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { withRouter } from 'react-router-dom';

import { UserGroupPlatformService, UserGroupTypeService, UserGroupType } from 'us-web-services';
import toast from 'react-hot-toast';
import DisplayService from 'src/util/DisplayService';
import { UserGroupPlatform } from 'us-web-services/dist/types/UserGroupPlatform';
import { Table, TableInstance } from '../common/Table';
import ConfirmDialog from '../common/ConfirmDialog';
import UserGroupTypeRangeEdit from './UserGroupTypeRangeEdit';

function ABTestAssignment() {
  const [userGroupPlatform, setUserGroupPlatform] = useState<UserGroupPlatform>({});
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [openEditPercentage, setOpenEditPercentage] = useState(false);
  const tableRef = React.useRef<TableInstance>();

  const refreshTable = () => tableRef.current?.fetchData();

  const postUpdate = () => {
    refreshTable();
    setOpenActivateDialog(false);
    setOpenDeactivateDialog(false);
    setOpenEditPercentage(false);
    toast.success('User group platform updated');
  };

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error updating the user group platform.',
    );

    toast.error(displayedError.message);
  };

  const updatePercentage = async (id: number, data: UserGroupType) => {
    UserGroupTypeService.patch(id, data).then(postUpdate).catch(showError);
  };

  const updateUserGroupPlatform = async (id: number, data: UserGroupPlatform) => {
    UserGroupPlatformService.patch(id, data).then(postUpdate).catch(showError);
  };

  const deactivateUserGroupPlatform = async () => {
    const data: UserGroupPlatform = {
      id: userGroupPlatform.id,
      enabled: false,
    };

    await updateUserGroupPlatform(userGroupPlatform.id, data);
  };

  const activateUserGroupPlatform = async () => {
    const data: UserGroupPlatform = {
      id: userGroupPlatform.id,
      enabled: true,
    };

    await updateUserGroupPlatform(userGroupPlatform.id, data);
  };

  const isActive = rowData => rowData.enabled === true;

  const COLUMNS = useMemo(
    () => [
      {
        id: 'userGroupPlatform',
        header: 'User Group Platform',
        columns: [
          {
            header: 'ID',
            accessorKey: 'id',
            enableEditing: false,
          },
          {
            header: 'Description',
            accessorKey: 'description',
            enableEditing: false,
          },
          {
            header: 'Campaign ID',
            accessorKey: 'campaignId',
            enableEditing: false,
          },
          {
            header: 'Platform',
            accessorKey: 'platform',
            enableEditing: false,
          },
          {
            header: 'Enabled',
            accessorKey: 'enabled',
            Cell: ({ cell }) => cell.getValue() ? 'Yes' : 'No',
          },
          {
            header: 'Actions',
            Cell: ({ cell }) => (
              <>
                {isActive(cell?.row?.original) ? (
                  <Button
                    size='small'
                    variant='outlined'
                    color='secondary'
                    onClick={() => {
                      setUserGroupPlatform(cell?.row?.original);
                      setOpenDeactivateDialog(true);
                    }}
                    style={{ margin: 5 }}
                  >
                    Deactivate
                  </Button>
                ) : (
                  <Button
                    size='small'
                    variant='outlined'
                    color='secondary'
                    onClick={() => {
                      setUserGroupPlatform(cell?.row?.original);
                      setOpenActivateDialog(true);
                    }}
                    style={{ margin: 5 }}
                  >
                    Activate
                  </Button>
                )}
                <Button
                  size='small'
                  variant='outlined'
                  color='secondary'
                  onClick={() => {
                    setUserGroupPlatform(cell?.row?.original);
                    setOpenEditPercentage(true);
                  }}
                  style={{ margin: 5 }}
                >
                  Edit
                </Button>
              </>
            ),
          },
        ],
      },
    ], [],
  );

  return (
    <>
      <ConfirmDialog
        confirmAction={deactivateUserGroupPlatform}
        open={openDeactivateDialog}
        setOpen={setOpenDeactivateDialog}
        title='Confirm AB test assignment Deactivation'
        text='Are you sure you want to deactivate this AB test assignment?'
      />
      <ConfirmDialog
        confirmAction={activateUserGroupPlatform}
        open={openActivateDialog}
        setOpen={setOpenActivateDialog}
        title='Confirm AB test assignment Activation'
        text='Are you sure you want to activate this AB test assignment?'
      />
      <UserGroupTypeRangeEdit
        userGroupPlatform={userGroupPlatform}
        updatePercentage={updatePercentage}
        open={openEditPercentage}
        setOpen={setOpenEditPercentage}
      />
      <Table
        columns={COLUMNS}
        ref={tableRef}
        retrieveData={UserGroupPlatformService.getByFilter}
      />
    </>
  );
}

export default withRouter(ABTestAssignment);
