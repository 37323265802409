import React, { useCallback, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import toast from 'react-hot-toast';
import { Company, CompanySafelistService, CsvParserService } from 'us-web-services';
import { CreateModal, Table, TableInstance } from 'src/components/common/Table';
import DisplayService from '../../../util/DisplayService';
import PageStyles from '../../../styles/PageStyles';
import DateUtil from '../../../util/DateUtil';

interface Props extends RouteComponentProps {
  company?: Company;
}

function CompanySafelist(props: Props) {
  const { company } = props;
  const [file, setFile] = useState(null);
  const tableRef = React.useRef<TableInstance>();
  const [parsed, setParsed] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(null);
  const [addCount, setAddCount] = useState(null);
  const [duplicateCount, setDuplicateCount] = useState(null);
  const [unknownCount, setUnknownCount] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const fileInput = React.useRef<HTMLInputElement>();
  const { classes } = PageStyles();

  const openConfirm = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const showError = useCallback(error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an unknown error replacing the safe list.',
      false,
    );

    displayedError.autoDismiss = false;
    toast.error(displayedError.message);
    setUploadSuccessful(false);
  }, []);

  const resetAfterUpload = () => {
    setFile(null);
    setParsed(false);
    setAddCount(null);
    setDuplicateCount(null);
    setUnknownCount(null);
    setUploadSuccessful(true);
  };

  const getNumberOfDuplicates = data =>
    data.length - new Set(data.map(e => e[0])).size;

  const processData = data => {
    let add = 0;
    let unknown = 0;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    data.forEach(row => {
      const email = row[0];

      if (emailRegex.test(email)) {
        add += 1;
      } else {
        unknown += 1;
      }
    });

    setAddCount(add);
    setDuplicateCount(getNumberOfDuplicates(data));
    setUnknownCount(unknown);
    setParsed(true);
  };

  const processFile = async () => {
    if (fileInput.current.files[0]) {
      setUploadSuccessful(null);
      setFile(fileInput.current.files[0]);
      const reader = new window.FileReader();

      reader.onload = e => {
        const data = CsvParserService.parse(e.target.result.toString(), ',', false);

        processData(data);
      };
      reader.readAsText(fileInput.current.files[0]);
    } else {
      toast.error('Uploaded file does not exist');
    }
  };

  const refreshCompanySafelist = () => tableRef.current?.fetchData();

  const uploadFile = async () => {
    if (file) {
      const formData = new FormData();

      formData.append('file', file);
      setShowConfirm(false);
      setUploading(true);

      await axios // TODO: migrate these inline API calls to us-web-services
        .post(`/v2/admin/company-safelist-bulk/${company.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(resetAfterUpload)
        .catch(showError)
        .finally(() => {
          setUploading(false);
          refreshCompanySafelist();
        });
    }
  };

  const deleteSafelist = async safelist => {
    await CompanySafelistService.delete(safelist.id).catch(showError);
  };

  const addSafelist = async values => {
    const data = {
      email: values.email,
      companyId: company.id,
    };

    await CompanySafelistService.create(data).catch(showError);
    refreshCompanySafelist();
  };

  const COLUMNS = useMemo(
    () => [
      {
        id: 'safelist',
        header: 'Safelist',
        columns: [
          {
            header: 'Email',
            accessorKey: 'email',
          },
          {
            header: 'Created',
            enableEditing: false,
            accessorFn: rowData =>
              rowData && rowData.created
                ? DateUtil.getReadableDateTime(rowData.created)
                : '',
          },
        ],
      },
    ],
    [],
  );

  const params = (globalFilter: string) => {
    const paramArray = [
      {
        key: 'companyId',
        value: company.id.toString(),
      },
    ];

    if (globalFilter) {
      paramArray.push({
        key: 'q',
        value: `email.contains('${encodeURIComponent(globalFilter)}')`,
      });
    }

    return paramArray;
  };

  return (
    <Paper className={classes.paper}>
      <Dialog open={showConfirm} onClose={closeConfirm}>
        <DialogTitle>Ready to upload?</DialogTitle>
        <DialogContent>
          <p>
            Are you sure you want to <b>replace</b> the existing {company.name}{' '}
            safelist with these {addCount} emails?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirm} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={uploadFile}
            disabled={uploading}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant='h6' gutterBottom>
        Manage Safelist
      </Typography>
      <p>
        Upload a .csv file to <b>replace</b> the current safelist.
      </p>
      <Grid container spacing={2}>
        <Grid item lg={4}>
          <input
            id='csv-file-company-safelist'
            type='file'
            ref={fileInput}
            onChange={processFile}
          />
        </Grid>
        <Grid item lg={4}>
          {parsed && (
            <Box>
              Add {addCount} {addCount === 1 ? 'email' : 'emails'}
            </Box>
          )}
          {parsed && duplicateCount > 0 && (
            <Box
              style={{
                color: 'red',
                fontWeight: 'bold',
              }}
            >
              {duplicateCount}{' '}
              {duplicateCount === 1 ? 'duplicate' : 'duplicates'} during parsing
            </Box>
          )}
          {parsed && unknownCount > 0 && (
            <Box
              style={{
                color: 'red',
                fontWeight: 'bold',
              }}
            >
              {unknownCount} {unknownCount === 1 ? 'error' : 'errors'} during
              parsing
            </Box>
          )}
        </Grid>
        <Grid item lg={4}>
          {parsed && (
            <Button
              color='primary'
              disabled={uploading}
              onClick={openConfirm}
              variant='contained'
            >
              {uploading ? 'Uploading...' : 'Upload'}
            </Button>
          )}
          {uploadSuccessful === true && (
            <div style={{ color: 'green' }}>
              <FontAwesomeIcon
                style={{
                  fontSize: 16,
                  marginRight: '0.75rem',
                }}
                icon={faCheckCircle}
              />
              <span>Upload successful!</span>
            </div>
          )}
          {uploadSuccessful === false && (
            <div style={{ color: 'red' }}>
              <FontAwesomeIcon
                style={{
                  fontSize: 16,
                  marginRight: '0.75rem',
                }}
                icon={faTimesCircle}
              />
              <span>Error occurred</span>
            </div>
          )}
        </Grid>
      </Grid>
      <Table
        columns={COLUMNS}
        ref={tableRef}
        retrieveData={CompanySafelistService.getByFilter}
        params={params}
        addAction={() => setCreateModalOpen(true)}
        deleteAction={deleteSafelist}
        alwaysApplyParams
      />
      <CreateModal
        columns={COLUMNS}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={addSafelist}
        title='Add to Safelist'
        buttonText='Add'
      />
    </Paper>
  );
}

export default withRouter(CompanySafelist);
