class UtilityService {
  static sentenceCase(text?: string) {
    if (!text || text.length === 0) {
      return text;
    }

    return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
  }
}

export { UtilityService };
