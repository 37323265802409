import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { UserGroupPlatform } from '../types/UserGroupPlatform';

const URL = '/v2/admin/campaign-platforms';

class UserGroupPlatformService {
  static getByFilter(config?: AxiosRequestConfig): Promise<PagedResponse<UserGroupPlatform>> {
    return axios.get(URL, config);
  }

  static patch(id: number, data: UserGroupPlatform, config?: AxiosRequestConfig): Promise<DataResponse<UserGroupPlatform>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }
}

export { UserGroupPlatformService };
