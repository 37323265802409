import React, { useMemo } from 'react';
import { Fade, Modal } from '@mui/material';

import { UserGroupType, UserGroupTypeService } from 'us-web-services';
import { UserGroupPlatform } from 'us-web-services/dist/types/UserGroupPlatform';
import ModalStyles from '../../styles/ModalStyles';
import Table, { TableInstance } from '../common/Table/Table';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  userGroupPlatform: UserGroupPlatform,
  updatePercentage: (id: number, data: UserGroupType) => void;
}

function UserGroupTypeRangeEdit(props: Props) {
  const { open, setOpen, userGroupPlatform, updatePercentage } = props;
  const { classes } = ModalStyles();
  const tableRef = React.useRef<TableInstance>();

  const refreshTable = () => tableRef.current?.fetchData();

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditAction = async ({ values }) => {
    await updatePercentage(values.id, values);
    refreshTable();
  };

  const params = () => [{
    key: 'campaignId',
    value: userGroupPlatform?.campaignId?.toString(),
  }];

  const COLUMNS = useMemo(
    () => [
      {
        id: 'userGroupType',
        header: 'User Group Type',
        columns: [
          {
            header: 'ID',
            accessorKey: 'id',
            enableEditing: false,
          },
          {
            header: 'Campaign ID',
            accessorKey: 'campaignId',
            enableEditing: false,
          },
          {
            header: 'Name',
            accessorKey: 'name',
            enableEditing: false,
          },
          {
            header: 'Label',
            accessorKey: 'label',
            enableEditing: false,
          },
          {
            header: 'Canonical Name',
            accessorKey: 'canonicalName',
            enableEditing: false,
          },
          {
            header: 'Range Start',
            accessorKey: 'rangeStart',
            enableEditing: true,
          },
          {
            header: 'Range Length',
            accessorKey: 'rangeLength',
            enableEditing: true,
          },
        ],
      },
    ], [],
  );

  return (
    <Modal
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      className={classes.modal}
      closeAfterTransition
      onClose={handleClose}
      open={open}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Table
            columns={COLUMNS}
            ref={tableRef}
            retrieveData={UserGroupTypeService.getByFilter}
            params={params}
            alwaysApplyParams
            enableInlineEditing
            editAction={handleEditAction}
          />
        </div>
      </Fade>
    </Modal>
  );
}

export default UserGroupTypeRangeEdit;
