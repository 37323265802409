import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { UserGroupType } from '../types/UserGroup';

const URL = '/v2/user-group-types';

class UserGroupTypeService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<UserGroupType>> {
    return axios.get(URL, config);
  }

  static patch(id: number, data: UserGroupType, config?: AxiosRequestConfig): Promise<DataResponse<UserGroupType>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }
}
export { UserGroupTypeService };
