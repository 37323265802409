import React from 'react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Company, Partner } from 'us-web-services';
import CardStyles from 'src/styles/CardStyles';

type Props = {
  userCompany?: Company;
};

function Partners({ userCompany }: Props) {
  const { classes } = CardStyles();

  const sortPartnerName = (a: Partner, b: Partner) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    // equal
    return 0;
  };

  const partnerContent =
    !userCompany || !userCompany.partners || userCompany.partners.length < 1 ? (
      <Typography variant='subtitle1' color='textPrimary'>
        none
      </Typography>
    ) : (
      <Grid container spacing={4}>
        {userCompany.partners
          .sort((a, b) => sortPartnerName(a.partner, b.partner))
          .map(p => (
            <Grid item key={p.id}>
              <FontAwesomeIcon
                style={{
                  fontSize: 16,
                  marginRight: '0.5rem',
                }}
                icon={faCheck}
              />
              {p.partner.name}
            </Grid>
          ))}
      </Grid>
    );

  return (
    <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography
            component='h5'
            variant='h5'
            style={{
              paddingBottom: '10px',
            }}
          >
            Partnership Eligibility
          </Typography>
          {partnerContent}
        </CardContent>
      </div>
    </Card>
  );
}

export default Partners;
